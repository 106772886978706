// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/m.bompaire/Projects/MGB/website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-bar-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/Bar.jsx" /* webpackChunkName: "component---src-templates-bar-jsx" */),
  "component---src-templates-book-bar-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/BookBar.jsx" /* webpackChunkName: "component---src-templates-book-bar-jsx" */),
  "component---src-templates-cheapest-bars-in-city-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/CheapestBarsInCity.jsx" /* webpackChunkName: "component---src-templates-cheapest-bars-in-city-jsx" */),
  "component---src-templates-region-page-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/RegionPage.jsx" /* webpackChunkName: "component---src-templates-region-page-jsx" */),
  "component---src-templates-department-page-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/DepartmentPage.jsx" /* webpackChunkName: "component---src-templates-department-page-jsx" */),
  "component---src-templates-alphabetic-bar-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/AlphabeticBar.jsx" /* webpackChunkName: "component---src-templates-alphabetic-bar-jsx" */),
  "component---src-templates-bar-by-zone-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/templates/BarByZone.jsx" /* webpackChunkName: "component---src-templates-bar-by-zone-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-top-bars-sympa-paris-metro-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/pages/top-bars-sympa-paris-metro.jsx" /* webpackChunkName: "component---src-pages-top-bars-sympa-paris-metro-jsx" */),
  "component---src-pages-top-bars-sympa-paris-jsx": () => import("/Users/m.bompaire/Projects/MGB/website/src/pages/top-bars-sympa-paris.jsx" /* webpackChunkName: "component---src-pages-top-bars-sympa-paris-jsx" */)
}

